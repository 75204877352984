.project {
  &-container {

  }

  &-contents {

    &__image {
      margin-left: auto;
      margin-right: auto;
      &-container {
      }
    }
  }
}
