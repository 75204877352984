.timeline {
  width: 100%;

  &-item {
    text-align: center;
    width: 100%;
    position: relative;
    padding-bottom: 20px;
    font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif;

    &::before {
      z-index: -1;
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      transform: translate(-4px);
      width: 8px;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(212, 227, 255, 0.2) 0%,
        rgba(212, 227, 255, 0.83) 29.69%,
        rgba(212, 227, 255, 0.65) 72.4%,
        rgba(212, 227, 255, 0) 100%
      );
    }

    &__year {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 84px;
      height: 84px;
      background: #1e5ac9;
      border-radius: 50%;
      margin-bottom: 20px;

      h3 {
        color: #fff;
        font-size: 20px;
        font-weight: 900;
        margin-bottom: 0;
      }
    }

    &__text {
      padding: 0;

      li {
        color: #1d1d1d;
        margin-top: 40px;
        padding-bottom: 40px;
        width: 50%;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          top: 15px;
          width: 110px;
        }

        &::after {
          content: "";
          position: absolute;
          top: 11px;
          width: 12px;
          height: 12px;
          border-radius: 12px;
        }

        &:nth-child(odd) {
          text-align: right;
          position: relative;
          transform: translateX(-50%) translate(-6px);
          left: 25%;
          padding-right: 145px;
        }

        &:nth-child(even) {
          text-align: left;
          position: relative;
          transform: translateX(50%) translate(-6px);
          right: -25%;
          padding-left: 158px;
        }

        &:nth-child(odd)::before {
          width: 133px;
          content: url( "../../assets/img/about/line_left_default.svg" );
          right: 0;
        }

        &:nth-child(odd):hover::before {
          content: url( "../../assets/img/about/line_left_clicked.svg" );
        }

        &:nth-child(even)::before {
          width: 133px;
          content: url( "../../assets/img/about/line_right_default.svg" );
          left: 0;
        }

        &:nth-child(even):hover::before {
          content: url( "../../assets/img/about/line_right_clicked.svg" );
        }

      }

      h4 {
        font-weight: 800;
        font-size: 16px;
      }
    }
  }
}

/* md - tablet */
@media screen and (max-width: 1024px) {
}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {
  .timeline-item__text li {
    font-size: 14px;
    margin-top: 20px;
    padding-bottom: 20px;
  }
}

/* xs - mobile */
@media screen and (max-width: 639px) {
  .timeline-item {
    text-align: left;

    &::before {
      left: 45px;
      transform: translateX(-50%);
    }

    &__year {
      width: 60px;
      height: 60px;
      margin-bottom: 12px;
      left: calc(45px - 30px);
      position: relative;

      h3 {
        font-size: 16px;
        font-weight: 900;
      }
    }

    &__text li {
      width: calc(100% - 40px);
      font-size: 12px;
      margin-top: 20px;
      padding-bottom: 20px;

      &::before {
        width: 100px;
      }

      &:nth-child(odd) {
        text-align: left;
        position: relative;
        right: inherit;
        left: 45px;
        transform: translateX(-6px);
        padding-left: 130px;
        padding-right: inherit;
      }

      &:nth-child(even) {
        text-align: left;
        position: relative;
        right: inherit;
        left: 45px;
        transform: translateX(-6px);
        padding-left: 130px;
      }

      &:nth-child(odd)::before {
        left: 9px;
      }

      &:nth-child(odd)::after {
        left: 0;
      }
    }
  }
}
