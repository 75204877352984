$about-logo-height: 3000px;

.about section {
  min-height: 100vh;
  width: 100%;
  position: relative;
}

/* main */
.about-main {
  height: 100vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-mask-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    );
  }

  &__text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    padding-inline: 120px;

    h1 {
      font-weight: 700;
      font-size: 66px;
      color: #444444;
      text-align: right;
      padding-bottom: 180px;
      font-weight: bold;
      font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif;
    }
  }
}

/* logo */
.about-logo {
  &-section {
    width: 100%;
    height: $about-logo-height;
    position: relative;
  }

  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;

  &-container {
    width: 100%;
  }

  &__img {
    &-cover {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    width: 470px;
    position: absolute;
  }

  &__desc {
    display: flex;
    align-items: center;
    user-select: none;
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #bababa;

    &-container {
      display: flex;
      align-items: center;
      padding: 30px;
    }

    &-cover {
      width: 100%;
    }

    h1 {
      color: #1e5ac9;
      font-size: 48px;
      font-weight: 800;
      line-height: 65px;
      margin-bottom: 9px;
    }

    p {
      color: #5b5b5b;
      font-size: 16px;
      line-height: 22px;
    }

    &:hover {
      transition: 0.2s;
      border-bottom: 1px solid #1d1d1d;

      h1 {
        transition: 0.2s;
        color: #ffad10;
      }
    }
  }
}

/* vision */
.about-vision {
  &-section {
    display: flex;
    align-items: center;
  }

  width: 100%;

  &__header {
    margin-bottom: 50px;
    text-align: center;
    display: block;

    h1 {
      font-weight: 900;
      font-size: 20px;
      color: #1d1d1d;
      margin-bottom: 50px;
    }

    p {
      font-size: 16px;
      color: #1d1d1d;
    }
  }

  &__body {
    width: 100%;

    > div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 6px;
    }

    &-grid:nth-of-type(1) {
      animation-delay: 0s;
    }
    &-grid:nth-of-type(2) {
      animation-delay: 0.4s;
    }
    &-grid:nth-of-type(3) {
      animation-delay: 0.8s;
    }
    &-grid:nth-of-type(4) {
      animation-delay: 1.2s;
    }
  }

  &__card {
    border: none !important;

    &-media {
      overflow: hidden;
      height: 272px;
      padding: 0 !important;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-content {
      text-align: center;
      padding: 32px 8px 8px 8px;

      h1 {
        font-weight: 700;
        font-size: 34px;
        color: #1e5ac9;
        margin-bottom: 14px;
        user-select: none;
      }

      span {
        font-weight: 400;
        font-size: 15px;
        color: #5b5b5b;
        user-select: none;
      }
    }

    &:hover {
      h1 {
        color: #ffad10;
        transition: 0.3s;
      }

      p {
        color: #464646;
        transition: 0.3s;
      }

      img {
        transform: scale(1.1);
        transition: 0.3s;
      }
    }
  }
}

/* org */
.about-org {
  &-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__header {
    margin-bottom: 50px;

    h1 {
      font-weight: 900;
      font-size: 20px;
      text-align: center;
      color: #1d1d1d;
    }
  }

  &__card {
    &-text {
      h1 {
        font-weight: 900;
        font-size: 18px;
        color: #1d1d1d;
        text-align: center;
      }
      p {
        font-weight: 500;
        font-size: 16px;
        color: #1d1d1d;
        text-align: center;
      }
    }

    &-img {
      &-wrapper {
        border: 1px solid #bababa;
        padding: 32px;
        margin-bottom: 20px;
        height: 100%;
        max-height: 560px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      max-width: 100%;
      max-height: 100%;
    }
  }
}

/* history */
.about-history {
  &-section {
  }

  padding-top: 60px;

  &__header {
    margin-bottom: 70px;

    h1 {
      text-align: center;
      color: #1d1d1d;
      font-weight: 900;
      font-size: 20px;
    }
  }
}

/* animation */
.animation-1,
.animation-2,
.animation-3 {
  opacity: 0;
}

.active {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.transparency {
  opacity: 0.3;
}

/* md - tablet */
@media screen and (max-width: 1024px) {
  .about-main__text h1 {
    font-size: 42px;
  }

  .about-logo {
    &__img {
      width: 300px;
    }

    &__desc {
      margin-bottom: 24px;
      padding-bottom: 12px;

      &-cover {
        margin: 0 20px;
      }

      h1 {
        font-size: 24px;
        font-weight: 800;
        line-height: 24px;
        margin-bottom: 18px;
      }

      p {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .about-vision {
    &__header {
      margin-bottom: 50px;

      h1 {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      p {
        font-size: 14px;
      }
    }

    &__card {
      &-media {
        height: 180px;
      }

      &-content {
        padding: 8px;

        h1 {
          font-size: 20px;
          margin-bottom: 12px;
        }

        span {
          font-size: 12px;
        }
      }
    }
  }

  .about-org {
    &__header {
      margin-bottom: 50px;

      h1 {
        font-size: 20px;
      }
    }

    &__card {
      &-text {
        h1 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }
      }

      &-img {
        &-wrapper {
          padding: 20px;
          margin-bottom: 10px;
          max-height: 250px;
        }
      }
    }
  }

  /* history */
  .about-history {
    padding-top: 80px;

    &__header {
      margin-bottom: 50px;
    }
  }
}
.space {
  height: 170px;
}
/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {

  .about-main__text {
    justify-content: center;
    padding-inline: 15px;

    h1 {
      font-size: 42px;
      padding-bottom: 80px;
    }
  }

  .about-logo {
    &__img {
      width: 240px;
    }

    &__desc {
      margin-bottom: 24px;
      padding-bottom: 12px;

      h1 {
        font-size: 24px;
        font-weight: 800;
        line-height: 24px;
        margin-bottom: 18px;
      }

      p {
        font-size: 12px;
        line-height: 14px;
      }

      &-cover {
        margin: 0;
      }
    }
  }

  .about-vision {
    &__header {
      margin-bottom: 30px;

      h1 {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      p {
        font-size: 14px;
      }
    }

    &__body {
      > div {
        grid-template-columns: 1fr 1fr;
        grid-gap: 6px;
      }
    }

    &__card {
      &-media {
        height: 180px;
      }

      &-content {
        padding: 8px;

        h1 {
          font-size: 20px;
          margin-bottom: 12px;
        }

        span {
          font-size: 12px;
        }
      }
    }
  }

  .about-org {
    margin-top: 120px;

    &__header {
      margin-bottom: 30px;

      h1 {
        font-size: 20px;
      }
    }

    &__card {
      &-text {
        h1 {
          font-size: 16px;
        }

        p {
          font-size: 12px;
        }
      }

      &-img {
        &-wrapper {
          padding: 20px;
          margin-bottom: 10px;
          max-height: 250px;
        }
      }
    }
  }

  .about-history {
    margin-top: 100px;

    &__header {
      margin-bottom: 40px;
    }
  }
  .space {
    height: 100px;
  }
}

/* xs - mobile */
@media screen and (max-width: 639px) {

  .about-main__text {
    height: 100%;
    width: 100%;
    justify-content: center;
    padding-inline: 15px;

    h1 {
      font-size: 28px;
    }
  }

  /* logo */
  .about-logo {
    padding-block: 120px 0;

    &-container {
      height: 100%;
    }

    &__desc {
      margin-bottom: 20px;
      padding-bottom: 12px;

      &-container {
      }

      &-cover {
        margin: 0;
      }

      h1 {
        font-size: 24px;
        font-weight: 800;
        line-height: 24px;
        margin-bottom: 9px;
      }

      p {
        font-size: 12px;
        line-height: 12px;
      }
    }

    &__img {
      width: 220px;
      position: absolute;
    }
  }

  /* vision */
  .about-vision {
    padding-top: 80px;

    &__body {
      width: 100%;
    }

    &__header {
      margin-bottom: 30px;

      h1 {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      p {
        font-size: 12px;
      }
    }

    &__card {
      &-media {
        height: 150px;
      }

      &-content {
        padding: 8px;

        h1 {
          font-size: 18px;
          margin-bottom: 8px;
        }

        span {
          font-size: 11px;
        }
      }
    }
    .space {
      height: 170px;
    }
  }

  /* org */
  .about-org {
    &__header {
      margin-bottom: 30px;

      h1 {
        font-size: 20px;
        font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif !important;
      }
    }

    &__card {
      &-text {
        h1 {
          font-size: 16px;
        }

        p {
          font-size: 12px;
        }
      }

      &-img {
        &-wrapper {
          padding: 20px;
          margin-bottom: 10px;
          max-height: 200px;
        }
      }
    }
  }

  /* history */
  .about-history {
    padding-top: 60px;

    &__header {
      margin-bottom: 20px;
    }
  }
}
