.Mui-selected {
  background-color: transparent !important;
  color: #1e5ac9 !important;
  font-weight: bold !important;
}

.pagination-ul button {
  &:hover {
    background-color: transparent;
    color: #ffad10;
  }

  svg {
    fill: #1e5ac9;
    &:hover {
      fill: #ffad10 !important;
    }
  }
}
