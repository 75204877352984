.list-template {
  padding: 45px 0;
  border-bottom: 1px solid #BABABA;

  &__category {
    font-weight: 700;
    font-size: 16px;
    color: #1E5AC9;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  &__content {
    margin-bottom: 10px;
  }

  &__tags {
    margin-bottom: 10px;

    span {
      color: #1E5AC9;
      margin-right: 5px;
    }
  }

  &__date {
    color: #BABABA;
  }

  .__highlight {
    color: rgba(245, 183, 64, 0.7) !important;
    margin-right: 0 !important;
  }
}

/* md - tablet */
@media screen and (max-width: 1024px){

}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px){
  .list-template {
    padding: 20px 0;

    &__title {
      font-size: 22px;
      margin-bottom: 18px;
    }
    &__content {
      font-size: 14px;
    }
    &__tags {
      font-size: 14px;
    }
    &__date {
      font-size: 14px;
    }
  }
}

/* xs - mobile */
@media screen and (max-width: 639px){
  .list-template {
    padding: 20px 0;

    &__title {
      font-size: 18px;
      margin-bottom: 18px;
    }
    &__content {
      font-size: 12px;
    }
    &__tags {
      font-size: 12px;
    }
    &__date {
      font-size: 12px;
    }
  }
}
