.progressbar {}

/* md - tablet */
@media screen and (max-width: 1024px){

}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px){

}

/* xs - mobile */
@media screen and (max-width: 639px){
    .progressbar {
        display: none;
    }
}
