.toolbar {
  margin-top: 80px;
  margin-bottom: 40px;

  &__filter,
  &__search {
    border-bottom: 1px solid #bababa;
  }
}

/* filter */
.toolbar__filter {
  &-select {
    min-width: 120px;

    div {
      font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif;
      font-weight: 500;
    }

    &__control {
      border: none !important;

      &--menu-is-open .toolbar__filter-select__indicator {
        transform: rotateX(180deg) !important;
        transition: 0.1s;
      }

      &--is-focused {
        box-shadow: none !important;
      }
    }

    &__single-value {
      color: #1e5ac9 !important;
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      border-radius: 10px !important;
      border: 1px solid rgba(186, 186, 186, 0.8);
      box-shadow: none !important;
      padding-inline: 5px;
    }

    &__option {
      color: #bababa !important;
      text-align: left;
      border-bottom: 1px solid rgba(186, 186, 186, 0.8);
      padding: 5px !important;
      cursor: pointer !important;

      &:active {
        position: relative;
        padding-top: 6px !important;
        padding-bottom: 4px !important;
      }

      &:nth-last-of-type(1) {
        border-bottom: none;
      }

      &--is-focused {
        background-color: transparent !important;
        color: #ffad10 !important;
      }
      &--is-selected {
        background-color: transparent !important;
        color: #1e5ac9 !important;
      }
    }

    input {
      color: transparent !important;
      text-shadow: 0 0 0 #2196f3;
      cursor: pointer !important;
    }

    &__value-container:active {
      position: relative;
      top: 1px;
    }

    &__placeholder {
      color: #bababa !important;
    }
  }
}

/* search */
.toolbar__search {
  padding-block: 8px;
  form {
    display: flex;

    input {
      width: 100%;
      background-color: transparent;
    }
  }

  input {
    color: #1e5ac9;
    width: 90%;

    &:focus {
      outline: none;
      vertical-align: center;
      color: #ffad10 !important;

      & + button img {
        color: #ffad10 !important;
      }
    }
  }

  button img {
    color: #bababa;
  }
}

/* md - tablet */
@media screen and (max-width: 1024px) {
}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {
  .toolbar {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .toolbar__filter {
    &-select {
      font-size: 14px;
      min-width: inherit;

      &__indicator svg {
        width: 14px;
      }
    }
  }

  .toolbar__search input {
    font-size: 14px;
  }
}

/* xs - mobile */
@media screen and (max-width: 639px) {
  .toolbar {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .toolbar__filter {
    &-select {
      font-size: 11px;
      min-width: inherit;

      &__indicator svg {
        width: 13px;
      }
    }
  }

  .toolbar__search input {
    width: 80%;
    font-size: 11px;
  }
}
