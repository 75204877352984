@import "../../assets/css/variables";

.move-top-btn {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999;
  transition: opacity 0.1s;

  button {
    width: 44px;
    height: 44px;
  }
}

/* Large */
@media screen and (max-width: 1200px) {
}

/* Medium */
@media screen and (max-width: 992px) {
}

/* Small */
@media screen and (max-width: 768px) {
}

/* X-Small */
@media screen and (max-width: 576px) {
  .move-top-btn {
    display: none;
  }
}
