.detail-page {}

.detail-page-container {
    margin-bottom: 60px;
}

.detail-page__content {
    border: 1px solid #DBDBDB;
    padding: 50px 64px;
    max-width: 768px;
    position: relative;
}
/* TODO: 어떻게 pagination 이랑 통일성 있게 맞출 수 있을지 고민 */

/* xs - mobile */
@media screen and (max-width: 639px){
    .detail-page__content {
        padding: 30px 20px;
    }
}

/* sm - mobile - 가로 회전 */
@media (min-width: 640px) and (max-width: 767px){
    .detail-page__content {
        padding: 50px 40px;
    }
}

/* md - tablet */
@media (min-width: 768px) and (max-width: 1023px){

}

/* lg 이상 - web */
@media (min-width: 1024px){

}
