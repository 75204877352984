.banner {
  width: 100%;
  height: 440px;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;

  &__img {
    object-fit: cover;
    height: 100% ;
    width: 100%;
    position: absolute;
    top: 0;

    &-backdrop {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      //background: rgba(0, 0, 0, 0.4);
    }
  }

  &__text {
    z-index: 2;

    &-category {
      color: #ffad10;
      font-size: 16px;
      margin-bottom: 15px;
    }

    &-menu {
      font-size: 44px;
      margin-bottom: 20px;
    }
  }
}

/* md - tablet */
@media screen and (max-width: 1024px) {
  .banner {
    height: 400px;
  }
}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {
  .banner {
    height: 360px;
    padding-inline: 12px !important;
    &__text {
      &-category {
        font-size: 16px;
      }
      &-menu {
        font-size: 36px;
      }

      &-desc {
        font-size: 14px;
      }
    }
  }
}

/* xs - mobile */
@media screen and (max-width: 639px) {
  .banner {
    height: 320px;

    &__text {
      &-category {
        font-size: 14px;
      }
      &-menu {
        font-size: 32px;
      }
      &-desc {
        font-size: 12px;
      }
    }
  }
}
