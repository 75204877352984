.go-list {
  margin-top: 80px;
  margin-bottom: 50px;

  &-wrapper {
    border-bottom: 1px solid #bababa;
  }

  &__icon {
    font-size: 12px !important;
  }

  &__btn {
    color: #bababa !important;
    font-size: 14px !important;
    font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif !important;
    &:hover {
      color: #ffad10 !important;
      background-color: inherit !important;
    }
  }
}

/* md - tablet */
@media screen and (max-width: 1024px) {
}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {
  .go-list {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
}

/* xs - mobile */
@media screen and (max-width: 639px) {
  .go-list {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
