@import "../../assets/css/effect";

.menu {
  padding: 20px 20px 12px;
  position: fixed;
  top: 0;
  right: 0;
  width: max(30%, 400px); /* width */
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 98;
  color: #FFFFFF;
  text-align: center;
  --animate-duration: 0.3s;
  animation: open 0.3s;
  user-select: none;
  overflow: scroll;

  &__icon {
    &-group {
      display: flex;
      justify-content: right;
      margin-bottom: 120px;
      align-items: center;

      button {
        margin-left: 8px;

        img {
          width: 25px;
          height: 25px;
        }
      }
    }

    &-search {
      border-bottom: 1px solid #ffffff;
      display: flex;
      margin-right: 16px;
      padding: 4px 0 4px 4px;
      position: relative;

      > div {
        width: 130px;
      }

      &__clear-button {
        display: none;
        position: absolute;
        right: 40px;
        top: 25%;
      }

      input {
        color: #ffad10;
        background: transparent;
        outline: none;
      }

      button {
        margin-left: 15px;
        svg:hover {
          circle,
          path {
            @include hoverIconEffect;
          }
        }
      }
    }

    &-global {
      position: relative;

      &-menu {
        position: absolute;
        color: #ffffff;
        transform: translateX(-50%);
        left: 50%;
        margin-left: 4px;
        border-top: 1px solid #ffffff;
        padding-top: 3px;
        padding-left: 0;

        > li {
          font-size: 14px;
          margin-bottom: 0;
          line-height: 1.8;
          font-weight: 500;
          font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif;

          button {
            margin: 0;
          }
        }
      }
    }

    &-close {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        margin: 0;

        svg:hover {
          path {
            @include hoverIconEffect;
          }
        }
      }
    }
  }

  &__item {
    font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif;

    &-group {
    }
    cursor: pointer;
    font-size: 44px;
    line-height: 53px;
    margin-bottom: 50px;
    font-weight: 700;

    &:hover {
      color: #ffffff;
    }

    &-link {
      color: #FFFFFF;

      &:hover {
        color: #ffad10;
      }
    }

    &-nested {
      margin-bottom: 50px;
      padding: 0;

      li {
        font-size: 22px;
        line-height: 19px;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 400;
      }
    }
  }
}

.menu .selected,
.menu .menu__item .menu__item-link.selected:hover {
  color: #ffad10;
}

.hide {
  right: min(-30%, -400px); /* width */
  transition-duration: 0.5s;
  animation: close 1s;
}

@keyframes open {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes close {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translateX(100%, 0, 0);
  }
}

/* md - tablet */
@media screen and (max-width: 1024px) {
  .menu {
    padding: 15px 20px 12px;

    &__icon {
      &-group {
        margin-bottom: 80px;
      }

      &-search input {
        font-size: 14px;
      }
      &-global-menu li {
        font-size: 14px;
      }
    }
    &__item {
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 48px;

      &-nested li {
        font-size: 14px;
        line-height: 14px;
        margin-block: 22px 0;
      }
    }
  }
}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {
  .menu {
    padding: 10px 20px 12px;

    &__icon {
      &-group {
        margin-bottom: 80px;

        button img {
          width: 20px;
          height: 20px;
        }
      }

      &-search input {
        font-size: 12px;
      }

      &-global-menu li {
        font-size: 12px;
      }
    }

    &__item {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 48px;

      &-nested li {
        font-size: 12px;
        line-height: 12px;
        margin-block: 20px 0;
      }
    }
  }
}

/* xs - mobile */
@media screen and (max-width: 639px) {
  .menu {
    padding: 8px 20px 12px;
    width: 100%; /* width */

    &__icon {
      &-group {
        margin-bottom: 80px;

        button img {
          width: 18px;
          height: 18px;
        }
      }

      &-search input {
        font-size: 11px;
      }

      &-global-menu li {
        font-size: 11px;
      }
    }
    &__item {
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 42px;

      &-nested li {
        font-size: 12px;
        line-height: 12px;
        margin-block: 20px 0;
      }
    }
  }

  .hide {
    right: -100%; /* width */
  }
}
