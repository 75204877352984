
.detail-post {
  &__header {
    display: inline-block;
    width: 100%;

    &-title {
      font-size: 44px;
      margin-bottom: 30px;
    }

    &-tags {
      margin-bottom: 15px;

      span {
        color: #1E5AC9;
        margin-right: 5px;
      }
    }

    &-date {
      color: #BABABA;
      margin-bottom: 30px;
    }
  }

  &__content {
    margin-bottom: 60px;
    font-size: 16px;
    line-height: 2;
    width: 100%;

    p {
      position: relative;

      img {
        width: calc(100% + 128px);
        position: relative;
        /*left: 0px;*/
        margin-block: 15px;
      }
    }
  }
}

/* md - tablet */
@media screen and (max-width: 1024px) {

}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {
  .detail-post {
    &__header {
      &-title {
        font-size: 24px;
        margin-bottom: 20px;
      }

      &-tags {
        font-size: 14px;
      }

      &-date {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }

    &__content {
      margin-bottom: 60px;
      font-size: 14px;
      line-height: 2;

      img {
        max-width: calc(100% + 80px);
        left: -40px;
      }
    }
  }
}

/* xs - mobile */
@media screen and (max-width: 639px) {
  .detail-post {
    &__header {
      &-title {
        font-size: 20px;
        margin-bottom: 20px;
      }

      &-tags {
        font-size: 12px;
      }

      &-date {
        font-size: 12px;
        margin-bottom: 20px;
      }
    }

    &__content {
      margin-bottom: 60px;
      font-size: 12px;
      line-height: 2;

      img {
        max-width: calc(100% + 40px);
        left: -20px;
      }
    }
  }
}
