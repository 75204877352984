.gallery-container {

}

.gallery-list {
  position: relative;

  &__isotope {
    margin: 50px 12px 50px;
    padding-top: 10px;
    overflow: hidden;
    /*border-top: 1px solid #BABABA;*/

    &-article {
      width: 25%;
      padding: 10px;
      cursor: pointer;
      overflow: hidden;

      img {
        width: 100%;
        transform: scale(1);
        transition: transform 0.5s;
        border-radius: 5px;

        &:hover {
          filter: brightness(0.8);
          transform: scale(1.01);
          transition: transform 0.5s;
        }
      }
    }
  }

  &__loader {
    position: absolute;
    bottom: -100px;
    color: #172b4d;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 150px 24px 0;
    height: 500px;
    background: linear-gradient(to bottom,
            rgba(255, 255, 255, 0) 3%,
            rgba(255, 255, 255, 0.3) 10%,
            rgba(255, 255, 255, 0.4) 15%,
            rgba(255, 255, 255, 0.5) 20%,
            rgba(255, 255, 255, 1) 40%);

    button {
      font-size: 16px;
      color: #bababa;

      &:hover {
        color: #ffad10;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

/* md - tablet */
@media screen and (max-width: 1024px) {
  .gallery-list {
    &__isotope {
      &-article {
        width: 33.333% !important;
      }
    }

    &__loader {
      button {
        font-size: 15px;
      }
    }
  }
}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {
  .gallery-list {
    &__isotope {
      &-article {
        width: 50% !important;
      }
    }
  }
}

/* xs - mobile */
@media screen and (max-width: 639px) {
  .gallery-list {
    &__isotope {
      margin: 30px 12px 30px;

      &-article {
        width: 100% !important;
      }
    }

    &__loader {
      font-size: 12px;
    }
  }
}
