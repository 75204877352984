.search {
  &__header {
    &-total {
      padding-top: 20px;
      color: #1e5ac9;
    }

    &-category {
      &-cover {
        padding-block: 20px;
        margin-block: 30px;
        display: flex;
        justify-content: center;
        border: 1px solid #bababa;
      }

      width: 100%;

      button {
        border-right: 2px solid rgba(186, 186, 186, 0.5);

        &:nth-last-of-type(1) {
          border-right: none;
        }

        &:active {
          position: relative;
          top: 1px;
        }
      }

      &-title,
      &-total {
        color: #5b5b5b;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
      }
    }
  }

  &__body {
    width: 100%;
  }

  &__footer {
    margin-block: 30px;
    grid-column: 1 / span 2;
  }

  &-error {
    text-align: center;
    color: #bababa;
    font-size: 20px;
    font-weight: 700;
    margin: 100px auto;
  }
}

.selected div {
  color: #1e5ac9;
}

.search-gallery-list {
  position: relative;

  &__isotope {
    margin: 50px 12px 50px;
    padding-top: 10px;
    overflow: hidden;
    /*border-top: 1px solid #BABABA;*/

    &-article {
      width: 25%;
      padding: 10px;
      cursor: pointer;
      overflow: hidden;

      img {
        width: 100%;
        transform: scale(1);
        transition: transform 0.5s;
        border-radius: 5px;

        &:hover {
          filter: brightness(0.8);
          transform: scale(1.01);
          transition: transform 0.5s;
        }
      }
    }
  }

  &__loader {
    position: absolute;
    bottom: -100px;
    color: #172b4d;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 3%,
      rgba(255, 255, 255, 0.3) 10%,
      rgba(255, 255, 255, 0.4) 15%,
      rgba(255, 255, 255, 0.5) 20%,
      rgba(255, 255, 255, 1) 40%
    );

    button {
      font-size: 16px;
      color: #bababa;

      p {
        margin-bottom: 0;
      }
    }
  }
  .not-end {
    padding: 150px 24px 0;
    height: 500px;

    button {
      &:hover {
        color: #ffad10;
      }
    }
  }
}

/* md - tablet */
@media screen and (max-width: 1024px) {
  .search__header {
    &-total {
      font-size: 14px;
    }

    &-category {
      &-cover {
        padding-block: 20px;
        margin-block: 25px;
      }

      &-title {
        font-size: 20px;
      }

      &-total {
        font-size: 18px;
      }
    }
  }
  .search-gallery-list {
    &__isotope {
      &-article {
        width: 33.333% !important;
      }
    }
    &__loader {
      button {
        font-size: 15px;
      }
    }
  }
}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {
  .search__header {
    &-total {
      font-size: 14px;
    }

    &-category {
      &-cover {
        padding-block: 20px;
        margin-block: 25px;
      }

      &-title {
        font-size: 18px;
      }

      &-total {
        font-size: 16px;
      }
    }
  }
  .search-gallery-list {
    &__isotope {
      &-article {
        width: 50% !important;
      }
    }
  }
}

/* xs - mobile */
@media screen and (max-width: 479px) {
  .search__header {
    &-total {
      font-size: 12px;
    }

    &-category {
      &-cover {
        padding-block: 15px;
        margin-block: 20px;
      }

      &-title {
        font-size: 16px;
      }

      &-total {
        font-size: 14px;
      }
    }
  }

  .search__footer {
    grid-column: 1;
  }
  .search-gallery-list {
    &__isotope {
      margin: 30px 12px 30px;

      &-article {
        width: 100% !important;
      }
    }

    &__loader {
      font-size: 12px;
    }
  }
}
