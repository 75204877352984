@import "../../assets/css/effect";

/* main 페이지의 section 공통요소 */
.main {
  section {
    height: 100%;
    display: block;
    padding-top: 180px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    &:nth-of-type(1) {
      padding-top: 0;
    }
  }

  &__title {
    margin-bottom: 50px;
    user-select: none;
    font-size: 20px;
    font-weight: 900;
    color: #1d1d1d;
  }

  &__text {
    font-size: 16px;
    font-weight: 500;
    color: #1d1d1d;
    margin: auto 12px;
  }

  &__actions {
    margin-top: 32px;
    cursor: pointer;

    button {
      color: #82a0d8;
      font-size: 14px;

      &:hover {
        @include hoverTextEffect;
      }
    }
  }
}

/*  HEROES 세션  */
.main-heroes {
  transition: 0.2s ease-in-out;
  &-container {
    display: grid;
    grid-template-columns: 1fr 1.2fr 4fr;
    grid-column-gap: 24px;
    height: 100%;
    padding-left: 85px;
  }

  // 사진
  &__image {
    &--sm1 {
      position: relative;
      top: 20%;

      img {
        width: 100%;
      }
    }
    &--sm2 {
      position: relative;
      top: 0;
      img {
        width: 100%;
      }
    }
    &--lg {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        padding-left: 0;
        padding-right: 0;
      }
    }

    // 글씨
    &--text {
      position: absolute;
      bottom: 24px;
      width: 33%;
      padding-right: 12px;

      &-container {
        border-bottom: 1px solid #bababa;
      }

      &--logo {
        position: absolute;
        animation: spin 6s linear infinite;
        transform: translate(-50%, -50%);
        top: -30px;
        left: -45px;
      }

      &--ment {
        padding-bottom: 12px;
      }
    }
  }

  .even {
    grid-template-columns: 4fr 1fr 1fr !important;
    padding-left: 0;
    padding-right: 85px;

    .main-heroes__image--lg {
      img {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .main-heroes__image--text {
      right: calc(85px - 12px);
      padding-right: 0;
      padding-left: 12px;

      .main-heroes__image--text--logo {
        left: inherit !important;
        right: -45px;
      }
    }
  }
}

.carousel {
  height: 100vh;

  &-inner {
    height: 100%;
  }

  &-indicators {
    width: 20px;
    top: 50%;
    left: 2rem !important;
    transform: translate(-50%, -50%);
    z-index: 2;
    flex-direction: column;
    padding: 0;
    margin: 1rem !important;
    list-style: none;

    button {
      width: 15px !important;
      height: 15px !important;
      border-radius: 15px;
      padding: 0;
      margin-top: 8px;
      cursor: pointer;
      border: 0 !important;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      background-color: #bababa !important;
    }

    .active {
      background-color: #ffad10 !important;
      opacity: 1 !important;
    }
  }

  &-item {
    height: 100%;
    animation: fade-out-slide 2s ease-in-out;
    display: block !important;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);

    .main-heroes__image--sm1 {
      animation: fade-out-slide 1.3s ease-in-out;
      display: block !important;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    .main-heroes__image--sm2 {
      animation: fade-out-slide 1.3s ease-in-out;
      display: block !important;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    .main-heroes__image--lg {
      animation: fade-out-slide 1.3s ease-in-out;
      display: block !important;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  }

  &-item.active {
    animation: fade-in-slide 2s ease-in-out;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    .main-heroes__image--sm1 {
      animation: fade-in-slide 1.5s ease-in-out;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .main-heroes__image--sm2 {
      animation: fade-in-slide 1.3s ease-in-out;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .main-heroes__image--lg {
      animation: fade-in-slide 1.2s ease-in-out;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}

/*  ABOUT 세션  */
.main-about {
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &-wrapper {
      position: relative;
      max-width: 1200px;
      width: 80%;
      margin: 64px auto;
    }

    &:nth-child(1) {
      position: relative;
    }
  }
}

.main-about__image-wrapper {
  position: relative;
}

.one_fade-in {
  opacity: 0;
}

.oneFadeIn {
  opacity: 1;
  transition: opacity 2s ease-in-out;
}

.star-fade-in-cover {
  position: absolute;
  width: 7%;
  transform: translate(-50%, -50%);
  top: 20%;
  left: 28%;

  img {
    opacity: 0;
  }
}

.starFadeIn {
  opacity: 1;
  animation: spinStar 2s cubic-bezier(0.59, 0.09, 0.34, 1.31);
  animation-fill-mode: forwards;
}

@keyframes spinStar {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}

/*  PROJECT 세션  */
.main-project {
  &__image {
    object-fit: cover;
    height: 100%;

    &-container {
      width: 100%;
      //max-width: 1700px;
      height: max(545px, calc(100vw / 3.2));
      margin: 55px auto 35px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 6px;
      transition: 0.3s cubic-bezier(0.67, 0.15, 0.36, 0.91);
    }

    &-wrapper {
      overflow: hidden;
      position: relative;
    }
  }
}

/*  topic 세션  */
.main-topic {
  position: relative;

  &-container {
    position: relative;
    padding-top: 3%;
  }

  &__image {
    &--logo {
      &--bg {
        position: absolute;
        max-width: 400px;
        width: 30%;
        top: -20%;
        left: -5%;
      }
      &--outline {
        position: absolute;
        max-width: 400px;
        width: 30%;
        top: -20%;
        left: -5%;
        animation: spin 8s linear infinite;
      }
    }
  }

  &__list {
    width: 80%;
    list-style: disc;
    text-align: left;
    top: 0;
    left: 0;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0;
    vertical-align: baseline;

    &-item {
      border-color: #bababa;
      display: block;
      line-height: 20px;
      list-style: none;
      box-sizing: border-box;
      border-bottom: 1px solid;
    }
  }

  &__list-item__text {
    &-container {
      opacity: 1;
      font-size: 60px;
      font-weight: 700;
      text-align: left;
      line-height: 1.8;
      height: 160px;
      display: flex;
      align-items: flex-end;
    }
    &-cover {
      cursor: pointer;
      position: absolute;
      user-select: none;

      span {
        font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif;
      }

      &:hover {
        transition: 0.1s;
        opacity: 0.9;

        .title {
          transition: 0.1s;
          color: #ffad10 !important;
        }
      }

      .index {
        -webkit-text-stroke: 1.8px #1d1d1d;
        color: transparent;
      }

      .title {
        width: auto;
        margin-left: 40px;
        color: #1d1d1d;
      }
    }
  }

  &__hover_image {
    animation: hover-image 1s 1;
    width: 260px;
    height: 364px;

    &-cover {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      position: relative;
      display: block;
      overflow: hidden;
    }

    &-container {
      filter: brightness(0.9);
      width: 300px;
      height: auto;
      position: fixed;
      pointer-events: none;
      transition: opacity 0.1s;
      animation: move-in-out 1s ease-in-out 0.2s;
      z-index: 999;
    }
  }

  &__title_effect {
    position: absolute;
    top: 92%;
    right: 0;
    font-weight: 900;
    font-size: 200px;
    transform: translateY(-50%);
    color: rgba(130, 160, 216, 0.15);
    user-select: none;
  }
}

/*  Gallery 세션  */
.main-gallery {
  padding-top: 100px !important;

  &-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin-top: 3%;
    border-left: 1px solid #dadada;
    border-top: 1px solid #dadada;
  }

  &__item {
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__text {
      font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      text-align: start;
      padding: 12px;
      font-size: 12px;
      font-weight: 700;
      line-height: normal;
      color: #9f9f9f;
    }
  }
}

/* animation */
@keyframes fade-in-slide {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fade-out-slide {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* 회전의 시작 각도를 지정합니다. */
  }
  100% {
    transform: rotate(360deg); /* 회전의 종료 각도를 지정합니다. */
  }
}

@keyframes move-in-out {
  0% {
    transform: translate(100%, 0px);
  }
  100% {
    transform: translate(0%, 0px);
  }
}

@keyframes hover-image {
  0% {
    transform: translate(-100%, 0px);
  }
  100% {
    transform: translate(0%, 0px);
  }
}

/* md - tablet */
@media screen and (max-width: 1024px) {
  .main-heroes {
    &-container {
      grid-column-gap: 18px;
      padding-left: 64px;
    }

    &__image {
      &--lg {
        img {
          padding-left: 32px;
        }
      }
    }

    .even {
      padding-right: 64px;

      .main-heroes__image--lg {
        img {
          padding-right: 32px;
        }
      }
    }
  }

  .main-gallery {
    &-container {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .main-project {
    &__image-container {
      height: 360px;
    }
  }

  .main-topic {
    &__list-item__text {
      &-container {
        font-size: 48px;
        height: 120px;
      }
      &-cover {
        .title {
          margin-left: 30px;
        }
      }
    }
    &__title_effect {
      font-size: 108px;
    }

    &__hover_image-container {
      width: 240px;
    }
  }
}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {
  .main-heroes {
    &-container {
      grid-column-gap: 12px;
      padding-left: 64px;
    }

    &__image {
      &--lg {
        img {
          padding-left: 24px;
        }
      }
    }

    .even {
      padding-right: 64px;

      .main-heroes__image--lg {
        img {
          padding-right: 24px;
        }
      }
    }
  }

  .main-gallery {
    &-container {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(6, 1fr);
    }

    &__item {
      &__text {
        font-size: 10px;
      }
    }
  }
  section .main__text,
  .main__actions button {
    font-size: 14px !important;
  }
  .main-project {
    &__image-container {
      grid-column-gap: 3px;
      height: 300px;
    }
  }
  .main-topic {
    &__list-item__text {
      &-container {
        font-size: 36px;
        height: 90px;
      }

      &-cover {
        .title {
          margin-left: 24px;
        }
      }
    }
    &__title_effect {
      font-size: 80px;
    }

    &__hover_image-container {
      display: none;
    }
  }
}

/* xs - mobile */
@media screen and (max-width: 639px) {
  .main-heroes {
    &-container {
      height: 100%;
      display: initial;
      padding: 60px 0 0 0;
    }

    // 사진
    &__image {
      &--sm1 {
        img {
          display: none !important;
        }
      }
      &--sm2 {
        img {
          display: none !important;
        }
      }
      &--lg {
        height: 100%;

        img {
          object-fit: cover;
          padding: initial;
          -webkit-mask-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(255, 255, 255, 0.4)),
            to(rgba(255, 255, 255, 0.4))
          );
        }
      }

      // 글씨
      &--text {
        left: initial;
        right: 0;
        top: initial;
        bottom: 75px;
        width: 100%;
        height: auto;
        padding: 12px;

        &--logo {
          position: absolute;
          animation: spin 6s linear infinite;
          transform: translate(-50%, -50%);
          top: -3px;
          left: 100px;
          width: 50px;
        }

        &--ment {
          margin-left: auto;
          width: 70%;
          padding-bottom: 7px;
        }
      }
    }

    .even {
      grid-template-columns: 1fr !important;
      padding-left: 0;

      .main-heroes__image--lg {
        img {
          grid-column: 1 / span 2;
          padding-right: 0;
        }
      }

      .main-heroes__image--text {
        right: initial;
        padding: 12px;

        .main-heroes__image--text--logo {
          left: inherit !important;
          right: -45px;
        }
      }
    }
  }

  .carousel {
    &-indicators {
      width: 100%;
      top: initial;
      bottom: 40px !important;
      left: 50% !important;
      right: initial;
      transform: translate(-50%, -50%);
      flex-direction: row;
      margin: 0 !important;

      button {
        margin-inline: 8px !important;
        background-color: #fcfcfc !important;
      }
    }

    &-item {
      height: 100%;
      animation: fade-out-slide 2s ease-in-out;
      display: block !important;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);

      .main-heroes__image--lg {
        animation: fade-out-slide 1.2s ease-in-out;
        display: block !important;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
      }
    }

    &-item.active {
      animation: fade-in-slide 2s ease-in-out;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);

      .main-heroes__image--sm1 {
        animation: fade-in-slide 1.5s ease-in-out;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
      .main-heroes__image--sm2 {
        animation: fade-in-slide 1.3s ease-in-out;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
      .main-heroes__image--lg {
        animation: fade-in-slide 1.2s ease-in-out;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .main__text,
  .main__actions button {
    font-size: 12px !important;
  }
  .main-project {
    &__image-container {
      height: 200px;
    }
  }
  .main-topic {
    &__list-item__text {
      &-container {
        font-size: 28px;
        height: 70px;
      }
      &-cover {
        .title {
          margin-left: 18px;
        }
      }
    }
    &__title_effect {
      font-size: 64px;
    }

    &__hover_image-container {
      width: 120px;
    }
  }
}
