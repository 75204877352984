.header {
    position: fixed;
    background: transparent;
    color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 97;

    &__nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 30px;

        &-brand {
            width: 42px;
            height: 42px;
            cursor: pointer;
            position: relative;

            &:active {
                top: 1px;
            }

            &__color {
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                z-index: 9;

                top: 0;
                background: url("../../assets/img/logo/logo_color.png") no-repeat;
                background-size: 42px 42px;
            }

            &__white {
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                z-index: 8;

                top: 0;
                background: url("../../assets/img/logo/logo_white.png") no-repeat;
                background-size: 42px 42px;
            }
        }

        &-menu {
            width: 42px;
            height: 42px;

            button {
                padding: 0;
                position: relative;
                width: 100%;
                height: 100%;
            }

            &__color {
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                z-index: 9;
                top: 0;
                background: url("../../assets/img/logo/menu_bar_color.png") no-repeat;
                background-size: 42px 42px;
            }

            &__white {
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                z-index: 8;
                top: 0;
                background: url("../../assets/img/logo/menu_bar_white.png") no-repeat;
                background-size: 42px 42px;
            }
        }
    }
}

/* md - tablet */
@media screen and (max-width: 1024px){
    .header {
        &__nav {
            &-brand, &-menu {
                width: 36px;
                height: 36px;

                div {
                    background-size: 36px 36px;
                }
            }
        }
    }
}


/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px){
    .header {
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(10px);

        &__nav {
            padding: 9px 14px;

            &-brand, &-menu {
                width: 32px;
                height: 32px;

                div {
                    background-size: 32px 32px;
                }
            }

            &-brand__color {
                background: url("../../assets/img/logo/logo_white.png") no-repeat;
            }
            &-menu__color {
                background: url("../../assets/img/logo/menu_bar_white.png") no-repeat;
            }
        }
    }
}

/* xs - mobile */
@media screen and (max-width: 639px){
    .header {
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(10px);

        &__nav {
            padding: 9px 14px;
        }

        &-brand, &-menu {
            width: 32px;
            height: 32px;

            div {
                background-size: 32px 32px;
            }
        }
    }
}
