.post-template {
  padding: 0 0 40px;
  margin-top: 20px;
  border-bottom: 1px solid #BABABA;

  &__img {
    &-wrapper {
      margin-bottom: 20px;
      cursor: pointer;
    }
    // max width = 1320 - 12 = 1308 -> 604 / 1.5 = 402
    height: 402px;
    width: 100%;
    object-fit: cover;
  }

  &__text {
    &-title {
      font-size: 24px;
      margin-bottom: 20px;
      cursor: pointer;
    }
    &-content {
      margin-bottom: 10px;
    }
    &-tags {
      margin-bottom: 10px;
      span {
        color: #1E5AC9;
        margin-right: 5px;
      }
    }
    &-date {
      color: #BABABA;
    }
  }
}

/* md - tablet */
@media screen and (max-width: 1024px){
  .post-template {
    padding: 0 0 20px;
    margin: 20px 0 0;

    &__img {
      &-wrapper {
        margin-bottom: 20px;
      }

      // 960 - 12 = 948 / 2 -> 329 / 1.5 = 220
      height: 220px;
    }
  }
}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px){
  .post-template {

    &__img {
      &-wrapper {
        margin-bottom: 20px;
      }

      // 239 / 1.5 = 160
      height: 160px;
      width: 100%;
      object-fit: cover;
    }

    &__text {
      &-title {
        font-size: 22px;
        margin-bottom: 22px;
      }
      &-content {
        font-size: 14px;
      }
      &-tags {
        font-size: 14px;
      }
      &-date {
        font-size: 14px;
      }
    }
  }
}

/* xs - mobile */
@media screen and (max-width: 639px){
  .post-template {
    &__img {
      &-wrapper {
        margin-bottom: 20px;
      }

      height: 160px;
      width: 100%;
      object-fit: cover;
    }

    &__text {
      &-title {
        font-size: 18px;
        margin-bottom: 18px;
      }
      &-content {
        font-size: 12px;
      }
      &-tags {
        font-size: 12px;
      }
      &-date {
        font-size: 12px;
      }
    }
  }
}
