#alert-dialog .MuiPaper-root {
  border-radius: 10px;
  width: 400px;
}

#alert-dialog-description{
  text-align: center;
  font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}

.alert-dialog-icon {
  margin-right: auto;
  margin-left: auto;
}

#alert-dialog-actions {
  display: flex;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 25px;
}

#alert-dialog-button {
  width: 50px;
  min-width: 50px;
  border: 1px solid #BABABA;
  font-size: 13px;
  color: #BABABA;
  padding: 2px 4px;
}

#alert-dialog-button:hover {
  background: transparent;
  border: 1px solid #FFAD10;
  color: #FFAD10;

}