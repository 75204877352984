// 재사용 스타일

@mixin hoverTextEffect {
  color: #ffad10;
}

@mixin focusTextEffect {
  color: #1e5ac9;
}

@mixin hoverIconEffect {
  stroke: #ffad10;
}

@mixin focusIconEffect {
  stroke: #1e5ac9;
}
