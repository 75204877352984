.gallery-list {
  &__modal {
    &-body {
      padding: 8px !important;
    }

    &-img {
      width: 100%;
      object-fit: contain;
    }

    &-tags {
      span {
        color: #ffffff;
        font-weight: 400;
      }
    }

    &-action {
      button {
        color: #ffffff;
        font-size: 30px;
        padding: 0;
      }
    }

    &-direction {
      button {
        color: #ffffff;
        font-size: 30px;
        font-weight: 300;
        z-index: 99;
      }

      &.prev {
        position: fixed;
        top: 50%;
        left: calc(50% - 350px - 30px);
        transform: translateX(-50%);
      }

      &.next {
        position: fixed;
        top: 50%;
        right: calc(50% - 350px - 30px);
        transform: translateX(50%);
      }

      .MuiModal-backdrop {
        background-color: rgba(0, 0, 0, 0.7);
      }

      .MuiPaper-root {
        max-width: 700px;
      }
    }

    .MuiPaper-root {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

/* md - tablet */
@media screen and (max-width: 1024px) {
  .gallery-list {
    &__modal {
      &-direction {
        &.prev {
          position: fixed;
          top: 50%;
          left: calc(50% - 300px - 15px);
          transform: translateX(-50%);
        }

        &.next {
          position: fixed;
          top: 50%;
          right: calc(50% - 300px - 15px);
          transform: translateX(50%);
        }
      }

      .MuiPaper-root {
        max-width: 600px;
      }
    }
  }
}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {
  .gallery-list {
    &__modal {
      &-direction {
        &.prev {
          position: fixed;
          top: 50%;
          left: calc(50% - 260px - 15px);
          transform: translateX(-50%);
        }

        &.next {
          position: fixed;
          top: 50%;
          right: calc(50% - 260px - 15px);
          transform: translateX(50%);
        }
      }

      .MuiPaper-root {
        max-width: 520px;
      }
    }
  }
}

/* xs - mobile */
@media screen and (max-width: 639px) {
  .gallery-list {
    &__modal {
      &-direction {
        &.prev {
          top: 50%;
          left: 5%;
          transform: translateX(-50%);
        }

        &.next {
          top: 50%;
          right: 5%;
          transform: translateX(50%);
        }
      }

      .MuiPaper-root {
        max-width: 500px;
      }
    }

    &__loader {
      font-size: 12px;
    }
  }
}
