.share-group svg {
  width: 45px;
  height: 45px;
  background-color: #f5f5f7;
  border-radius: 45px;
  color: #6d6d6d;
  padding: 7px;
  margin: 10px;

  path {
    height: 25px;
  }

  #talk-text {
    fill: #f5f5f7;
  }

  &:hover {
    background-color: #fff6df;
    color: #ffad10;

    path {
      fill: #ffad10;
    }

    .twitter_stroke_area {
      fill: transparent;
      stroke: #ffad10;
    }

    #talk-text {
      fill: #fff6df;
    }
  }
}

/* md - tablet */
@media screen and (max-width: 1024px) {
}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {
  .share-group svg {
    width: 32px;
    height: 32px;
    padding: 6px;
    margin: 7px;
  }

  .swal {
    &_title {
      font-size: 20px;
    }
    &_input {
      font-size: 16px;
    }
    &_actions {
      height: 40px;
    }
  }
}

/* xs - mobile */
@media screen and (max-width: 639px) {
  .share-group svg {
    width: 28px;
    height: 28px;
    padding: 5px;
    margin: 6px;
  }

  .swal {
    &_title {
      font-size: 18px;
    }
    &_input {
      font-size: 14px;
    }
    &_actions {
      height: 32px;
    }
  }
}
