.detail-pagination {
  font-size: 16px;
  color: #bababa;
  max-width: 768px;
  margin-top: 10px;

  &__move-btn {
    color: #6d6d6d !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif !important;

    &-arrow {
      font-size: 12px !important;
    }

    &:hover {
      color: #ffad10 !important;
      background-color: inherit !important;
    }
  }

  &__tooltip {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    user-select: none;
  }

  &__list-btn {
    &-container {
      text-align: center;

      button {
        color: #bababa !important;
        font-size: 16px !important;
        font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif !important;
        &:hover {
          color: #ffad10 !important;
          background-color: inherit !important;
        }
      }
    }
  }
}

/* md - tablet */
@media screen and (max-width: 1024px) {
}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {
  .detail-pagination {
    font-size: 14px;
    margin-top: 16px;
    justify-content: space-between;

    &__move-btn {
      font-size: 14px !important;
    }

    &__tooltip {
      padding: 6px 8px;
      overflow: hidden;
    }

    &__list-btn {
      &-container button {
        font-size: 14px !important;
      }
    }
  }
}

/* xs - mobile */
@media screen and (max-width: 639px) {
  .detail-pagination {
    font-size: 14px;
    margin-top: 16px;
    justify-content: space-between;

    &__move-btn {
      font-size: 12px !important;
    }

    &__tooltip {
      padding: 6px 8px;
      display: none;
    }

    &__list-btn {
      &-container button {
        font-size: 12px !important;
      }
    }
  }
}
