.error {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;

  &__img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

    &-backdrop {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      //background: rgba(0, 0, 0, 0.4);
    }
  }
  &__text {
    font-size: 20px;

    &-container {
      z-index: 2;
      text-align: center;
    }

    &-title {
      color: #FFFFFF;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    &-desc {
      font-size: 16px;
      color: #E6E6E6;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }
  &__back {
    button {
      background-color: #F5F5F7;
      color: #6D6D6D;
      padding: 8px 15px;
      border-radius: 40px;
    }
  }
}


/* md - tablet */
@media screen and (max-width: 1024px) {
  .error__img {
      background-position: 10% 50%;
  }
}

/* sm - mobile - 가로 회전 */
@media screen and (max-width: 768px) {
  .error__img {
      background-position: 13% 50%;
  }
}

/* xs - mobile */
@media screen and (max-width: 639px) {
  .error__img {
      background-position: 25% 50%;
  }
}
