@import "../../assets/css/effect";

.footer {
  padding: 60px 120px;
  background: #ffffff;

  &__top {
    &__logo {
      width: 219px;
      &-cover {
        margin-bottom: 122px;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: flex-end;
    justify-content: end;

    &-copyright {
      font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif;
      font-size: 15px;
      color: #6d6d6d;
    }

    &-wrapper select {
      height: 40px;
      padding-inline: 20px;
      color: #6d6d6d;
    }

    &__sns {
      button {
        background-color: #f5f5f7;
        width: 40px;
        height: 40px;
        margin-left: 5px;

        &:hover {
          background-color: #fff6df;
        }
      }
    }

    // family site
    &__family {

      &-form {
        box-sizing: border-box;
        display: flex;
        position: relative;
        z-index: 1;
        border-radius: 20px;
        height: 100%;
        background: #f5f5f7;
        cursor: pointer;

        &:before {
          content: url("../../assets/img/icon/arrow.svg");
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          right: 10px;
          z-index: -1;
        }

        &:hover {
          background: #fff6df;
          color: #ffad10;

          &:before {
            content: url("../../assets/img/icon/arrowColor.svg");
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            right: 10px;
            z-index: -1;
          }
        }

      }

      &-select-label {
        font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif;
        font-weight: 500;
        letter-spacing: 0.5px;
        width: 100%;
        border-radius: 20px;
        padding-inline: 20px;
        text-align: start;
      }

      &-menu-items {
        z-index: 2;
        position: absolute;
        top: 28px;
        left: 0;
        width: 100%;
        background: #ffffff;
        color: #bababa;
        list-style-type: none;
        padding: 0;
        border-radius: 21px;
        overflow: hidden;
        max-height: 0;
        transition: 0.3s ease-in;
      }
    }
  }
}

.footer__bottom__family-form
  .footer__bottom__family-menu-item:last-child:before {
  content: url("../../assets/img/icon/arrow.svg");
  position: absolute;
  transform: translateX(-50%) rotateX(180deg);
  right: 10px;
}

.footer__bottom__family-form
  .footer__bottom__family-menu-item:last-child:hover:before {
  content: url("../../assets/img/icon/arrowColor.svg");
  position: absolute;
  transform: translate(-50%, -50%) rotateX(180deg);
  top: calc(90% - 0.5px);
  right: 10px;
}

.footer__bottom__family-form .footer__bottom__family-menu-items {

}

.footer__bottom__family-menu-items::-webkit-scrollbar {
  width: 6px;
}

.footer__bottom__family-menu-items::-webkit-scrollbar-track {
  background: transparent;
}

.footer__bottom__family-menu-items::-webkit-scrollbar-thumb {
  background: #303030;
  border-radius: 45px;
}
.footer__bottom__family-menu-items::-webkit-scrollbar-thumb:hover {
  background: #303030;
}

.footer__bottom__family-form.active {
  z-index: 10;
}

.footer__bottom__family-menu-items {
  opacity: 0;
  position: absolute;
  top: calc(-152% + 1px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.footer__bottom__family-form.active .footer__bottom__family-menu-items {
  opacity: 1;
  max-height: 500px;
  position: absolute;
  top: calc(-152% + 1px);
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  border: 1px solid #bababa;
}

.footer__bottom__family-form .footer__bottom__family-menu-item {
  padding-inline: 19px;
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 14px;
  transition: 0.1s;
  font-family: "Noto Sans", "Apple SD Gothic Neo", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.footer__bottom__family-form .footer__bottom__family-menu-item:hover {
  background: #fff6df;
  color: #ffad10;
}

.footer__bottom__family-form .footer__bottom__family-menu-item:last-child {
  border-bottom: 0 none;
}

/* Medium */
@media screen and (max-width: 992px) {
  .footer {
    padding: 60px 30px 90px;
  }
}

/* Small */
@media screen and (max-width: 768px) {
  .footer {
    padding: 50px 30px;
  }

  .footer__top__logo-cover {
    margin-bottom: 20px;
  }

  .footer__top__logo {
    max-width: 200px;
  }

  .footer__bottom-copyright {
    font-size: 12px;
  }

  .footer__bottom {
    justify-content: right;
  }

  .footer__bottom__sns {
    text-align: right;
  }

  .footer__bottom__sns button {
    width: 32px;
    height: 32px;
  }

  .footer__bottom__family {
    min-width: 150px;
  }

  .footer__bottom__family-form > div {
    height: 32px;
  }

  .footer__bottom__family-menu-item > div {
    font-size: 12px;
  }

  .footer__bottom__family-form {
    height: 30px;
    min-width: 140px;
  }

  .footer__bottom__family-select-label {
    border-radius: 20px;
    padding-inline: 12px;
    font-size: 11px;
  }

  .footer__bottom__family-form .footer__bottom__family-menu-items {
    border-radius: 18px;
  }

  .footer__bottom__family-form .footer__bottom__family-menu-item {
    padding-inline: 11px;
    height: 30px;
    font-size: 11px;
  }
}

/* X-Small */
@media screen and (max-width: 639px) {
  .footer {
    padding: 30px 20px;
  }

  .footer__top__logo-cover {
    margin-bottom: 8px;
  }

  .footer__top__logo {
    max-width: 128px;
  }

  .footer__bottom-copyright {
    font-size: 8px;
  }

  .footer__bottom {
    justify-content: right;
  }

  .footer__bottom__sns {
    text-align: right;
  }

  .footer__bottom__sns button {
    width: 28px;
    height: 28px;
  }

  .footer__bottom__family {
    min-width: 120px;
  }

  .footer__bottom__family-form > div {
    height: 28px;
  }

  .footer__bottom__family-menu-item > div {
    font-size: 12px;
  }
}
